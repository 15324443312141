/*
  =======================
    アイコン
  =======================
*/
.icon {
	position: relative;
	top: 2px;
	margin-right: .15em;
}
@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot?uhmk8h');
	src: url('../fonts/icomoon.eot?uhmk8h#iefix') format('embedded-opentype'),
		 url('../fonts/icomoon.ttf?uhmk8h') format('truetype'),
		 url('../fonts/icomoon.woff?uhmk8h') format('woff'),
		 url('../fonts/icomoon.svg?uhmk8h#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e926";
}
.icon-edit:before {
  content: "\e923";
}
.icon-reserve:before {
  content: "\e924";
}
.icon-list:before {
  content: "\e925";
}
.icon-swipe:before {
  content: "\e922";
}
.icon-replay:before {
  content: "\e90f";
}
.icon-send:before {
  content: "\e910";
}
.icon-newlabel .path1:before {
  content: "\e911";
  color: rgb(0, 0, 0);
  opacity: 0.35;
}
.icon-newlabel .path2:before {
  content: "\e91c";
  margin-left: -0.998046875em;
  color: rgb(0, 0, 0);
}
.icon-newlabel .path3:before {
  content: "\e91d";
  margin-left: -0.998046875em;
  color: rgb(0, 0, 0);
}
.icon-newlabel .path4:before {
  content: "\e91e";
  margin-left: -0.998046875em;
  color: rgb(0, 0, 0);
}
.icon-newlabel .path5:before {
  content: "\e91f";
  margin-left: -0.998046875em;
  color: rgb(255, 255, 255);
}
.icon-newlabel .path6:before {
  content: "\e920";
  margin-left: -0.998046875em;
  color: rgb(255, 255, 255);
}
.icon-newlabel .path7:before {
  content: "\e921";
  margin-left: -0.998046875em;
  color: rgb(255, 255, 255);
}
.icon-arrow_down:before {
  content: "\e900";
}
.icon-arrow_fp:before {
  content: "\e901";
}
.icon-arrow_left:before {
  content: "\e902";
}
.icon-arrow_lp:before {
  content: "\e903";
}
.icon-arrow_right:before {
  content: "\e904";
}
.icon-arrow_up:before {
  content: "\e905";
}
.icon-add_circle:before {
  content: "\e906";
}
.icon-add:before {
  content: "\e907";
}
.icon-border_wave:before {
  content: "\e908";
}
.icon-calender:before {
  content: "\e909";
}
.icon-cancel:before {
  content: "\e90a";
}
.icon-check:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90c";
}
.icon-delete:before {
  content: "\e90d";
}
.icon-hearing:before {
  content: "\e90e";
}
.icon-link:before {
  content: "\e912";
}
.icon-lock:before {
  content: "\e913";
}
.icon-map:before {
  content: "\e914";
}
.icon-person_add:before {
  content: "\e915";
}
.icon-pointer:before {
  content: "\e916";
}
.icon-search:before {
  content: "\e917";
}
.icon-sort:before {
  content: "\e918";
}
.icon-star-h:before {
  content: "\e919";
}
.icon-star:before {
  content: "\e91a";
}
.icon-time:before {
  content: "\e91b";
}
