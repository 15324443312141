/*
	=======================
		レイアウト
	=======================
*/
.wrapper {
	max-width: 1400px;
	margin: auto;
	&1000 {
		max-width: 1000px;
		margin: auto;
	}
}
.wrap {
	margin: 3em auto;
}
@include mq(tab) {
	.wrapper {
		&1000 {
			padding: 1em;
		}
	}
}