/*
	=======================
		ヘッダー
	=======================
*/
.header {
	&-inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1em 0;
		order: 5;
		.item {
			text-align: center;
			color: $blue;
			font-size: 1.4rem;
			font-weight: bold;
			letter-spacing: 1px;
			&:nth-child(1) {
				order: 3;
			}
			&:nth-child(2) {
				order: 1;
			}
			&:nth-child(3) {
				order: 2;
			}
			&:nth-child(4) {
				order: 4;
			}
			&:nth-child(5) {
				order: 5;
			}
			span {
				font-size: .8rem;
				font-weight: normal;
			}
		}
		.logo {
			width: 40%;
		}
	}
}
@include mq {
	.mobile-header {
		width: 80%;
		margin: auto;
	}
	.mobile-nav {
		position: fixed;
		top: 0;
		left: calc(-100vw - 1em);
		width: 100%;
		height: 100%;
		background: #fff;
		z-index: 999;
		transition: all .4s;
		& > .item {
			padding: 1.5em;
			.logo {
				padding: 1em 0;
				border-bottom: 1px dotted $blue;
			}
			.inner {
				margin: 1.5em auto 2.5em;
				li {
					margin-bottom: 1em;
					a {
						padding: .5em 0;
						position: relative;
						font-size: 1.2rem;
						color: $blue_r;
						font-weight: bold;
						&:hover {
							&::after {
								width: 100%;
							}
						}
						&::after {
							content: "";
							display: block;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 1em;
							height: 1px;
							background-color: $blue_r;
							transition: all .4s;
						}
					}
				}
			}
		}
	}
	.mobile-menu {
		position: fixed;
		top: 1%;
		right: 0;
		font-size: 2rem;
		line-height: 0;
		padding: .25em;
		z-index: 99;
		i {
			color: $blue_r;
		}
	}
	.menu-close {
		font-size: 2rem;
		line-height: 1;
		padding: .25em;
		position: absolute;
		top: 1%;
		right: 0;
		i {
			color: $blue_r;
		}
	}
}