/*
	=======================
		ページ / faq
	=======================
*/
.faq {
	p {
		line-height: 1.6;
		margin: 2em auto;
	}
	&-wrap {
		margin-bottom: 4em;
	}
}