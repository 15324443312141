/*
	=======================
		ページ / document
	=======================
*/
.document {
	.table {
		margin: 1em auto;
		thead {
			background: $gray_r;
		}
		th {
			border: 1px solid $gray_n;
			&:first-child {
				text-align: left;
			}
		}
		td {
			border: 1px solid $gray_n;
		}
		.dc_name {
			width: 60%;
		}
		.dc_format {
			width: 15%;
			text-align: center;
			text-transform: uppercase;
		}
		.dc_size {
			width: 15%;
			text-align: center;
		}
	}
}
@include mq {
	.document {
		.table {
			thead {
				th {
					font-size: .85rem;
					padding: .5em .5em;
					&:first-child {
						width: 40%;
					}
					&:not(:first-child) {
						width: 20%;
					}
				}
			}
			th,td {
				display: table-cell;
			}
			th {

			}
			td {
				font-size: .7rem;
			}
			.dc_name {
				a {
					font-size: .9rem;
				}
			}
			.dc_format {

			}
			.dc_size {
			}
		}
	}
}