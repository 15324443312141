/*
	=======================
		ページ / member
	=======================
*/
.member {
	margin: 2em auto;
	.item {
		&-inner {
			padding: 1em;
			.position {
				margin-bottom: .5em;
				font-size: .9rem;
			}
			.title {
				font-size: 1.2rem;
			}
			.ruby {
				font-weight: 100;
				font-size: .9rem;
			}
		}
	}
}
.member_col {
	background-color: #000;
	color: #fff;
	max-width: 1200px;
	margin: auto;
	padding: 2em;
	.item-l {
		flex-basis: 70%;
		padding-right: 2em;
		.table {
			font-size: .9rem;
			th {
				width: 12%;
				padding: 1em 0;
			}
			td {
				width: 80%;
				text-align: left;
				.cfs-hyperlink {
					color: #fff;
					text-decoration: underline;
				}
			}
		}
	}
	.item-r {
		flex-basis: 30%;
	}
	&2_title {
		text-align: left;
		padding-bottom: 1em;
		border-bottom: 1px solid;
		margin-bottom: 1em;
		.position {
			margin-bottom: .25em;
		}
		.title {
			font-weight: bold;
			font-size: 1.4rem;
		}
		.ruby {
			font-size: .9rem;
		}
	}
}
@include mq(tab) {
	.member_col {
		max-width: 90%;
	}
}
@include mq {
	.member {
		.item {
			&-inner {
				padding: .5em;
				.position {
					margin-bottom: .5em;
				}
				.title {
					font-size: 1.4rem;
				}
				.ruby {
					font-weight: 100;
				}
			}
		}
	}
	.member_col {
		overflow: auto;
		flex-wrap: wrap;
		order: 3;
		.item-r {
			order: 1;
			flex-basis: 100%;
			margin-bottom: 1em;
		}
		.item-l {
			order: 2;
			flex-basis: 100%;
			padding: 0;
			.member_col2_title {
				text-align: center;
				margin-bottom: 2em;
			}
			.table {
				th,td {
					display: block;
					width: 100%;
				}
			}
		}
		.close {
			position: fixed !important;
			right: initial !important;
			left: 0 !important;
		}
		&2_title {
			.position {
				font-size: .8rem;
			}
			.title {
				font-weight: bold;
				font-size: 1.4rem;
			}
		}
	}
}