/*
	=======================
		投稿ページ
	=======================
*/
.single {
	&_thumb {
		margin: 2em auto;
	}
	&>h1 {
		font-size: 2rem;
		margin: 2em auto 1em;
		padding: .5em 1em;
		border-radius: 4px;
		background: $gray_r;
	}
	&>h2 {
		font-size: 1.6rem;
		margin: 2em auto 1em;
		&::after {
			content: "";
			display: block;
			width: 3em;
			height: 1px;
			background-color: $black_n;
			margin-top: .5em;
		}
	}
	&>h3,&>h4,&>h5,&>h6 {
		font-size: 1.2rem;
		margin: 2em auto 1em;
	}
	&>p {
		margin: 2em auto;
		line-height: 2;
	}
}
@include mq {
	.single {
		&>p {
			font-size: 16px;
		}
	}
}