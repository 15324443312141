/*
	=======================
		ページ　/ principle
	=======================
*/
.principle {
	margin: 2em auto 4em;
	&_section {
		border: 1px solid $blue_r;
		padding: .5em;
		.inner {
			border: 1px solid $blue_r;
			padding: 1.5em;
			.item {
				.title {
					color: $blue_r;
					font-size: 1.7rem;
					margin-bottom: .5em;
				}
				.txt {
					line-height: 2;
					font-size: .9rem;
					span {
						display: block;
						padding-left: 1em;
					}
				}
			}
			.item-l {
				flex-basis: 70%;
			}
			.item-r {
				flex-basis: 30%;
			}
		}
	}
	&_section2 {
		margin: 3em auto;
		.item {
			padding: 1em;
			border: 1px solid $blue_r;
			.title {
				color: $blue_r;
				font-size: 1.6rem;
				margin-top: 0em;
				margin-bottom: .5em;
			}
		}
	}
}
@include mq(tab) {
	.principle {

	}
	.principle_section {
		.inner {
			.item-l {
				flex-basis: 65%;
				padding-right: 1em;
			}
			.item-r {
				flex-basis: 35%;
			}
		}
	}
	.principle_section2 {
		.item {
			&:first-child {
			}
		}
	}
}
@include mq {
	.principle {
		margin-bottom: 0;
	}
	.principle_section {
		.inner {
			display: block;
			.item-l {
				margin-bottom: 1em;
			}
		}
	}
	.principle_section2 {
		display: block;
		margin: 1em auto;
		.item {
			&:first-child {
				margin-bottom: 1em;
			}
		}
	}
}