/*
	=======================
		ボタン
	=======================
*/
.normal_button {
	width: 15em;
	padding: 1em 0;
	color: #fff;
}
.btn {
	display: inline-block;
	margin: 1em auto;
	line-height: 1;
	&:hover {
		box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.35);
		&:active {
			box-shadow: none;
		}
	}
	&_n {
		@extend .normal_button;
		background-color: $gray;
	}
	&_b {
		@extend .normal_button;
		background-color: $black_n;
	}
}
.btn_submit {
	font-size: 1rem;
	padding: 1.5em 5em;
	color: #fff;
	background: $blue_r;
	border-radius: 8px;
	transition: all .2s;
	margin: 2em auto 4em;
}
@include mq {
}