/*
	=======================
		ページ / outline
	=======================
*/
.outline {
	h2 {
		border: none !important;
		padding: 0 !important;
	}
	.map {
		margin: 4em auto;
	}
}
.plan {
	margin-bottom: 4em;
	flex-wrap: wrap;
	.item {
		ul {
			li {
				margin-bottom: 1em;
			}
		}
	}
}
@include mq(tab) {
	.outline {
		.map {
			margin: 2em auto;
		}
	}
	.plan {
		display: block;
		margin-bottom: 2em;
		.item {
			margin-bottom: 1em;
		}
	}
}