/*
	=======================
		ページ / スケジュール
	=======================
*/
.tablepress-id-2 {
	td {
		border-color: #fff !important;
	}
	.column-1 {
		width: 10%;
		background-color: #8b8f85;
		color: #fff;
	}
	.row-1,.row-8,,.row-14 {
		.column-2 {
			background-color: #d2e8d4;
		}
	}
	.row-2,.row-7,.row-11 {
		.column-2 {
			background-color: #4492c0;
			color: #fff;
		}
	}
	.row-3,.row-13 {
		.column-2 {
			background-color: #c7e9e8;
		}
	}
	.row-4,.row-5,.row-6 {
		.column-2 {
			background-color: #f8e2be;
		}
	}
	.row-9,.row-10,.row-12 {
		.column-2 {
			background-color: #fadad4;
		}
	}
}
@include mq {
	.tablepress-id-2 {
		margin-top: 2em;
		th,td {
			width: 100% !important;
		}
	}
}