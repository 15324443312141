.triangle {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 20px 20px 0 20px;
}
.centering {
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}