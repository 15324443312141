@charset "UTF-8";
/*
	=======================
		リセット | 共通設定
	=======================
*/
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  width: 100%;
  height: 100%; }

body {
  margin: 0;
  font-size: 16px;
  color: #191919;
  font-family: 'Segoe UI',Helvetica,'メイリオ', Meiryo,-apple-system, BlinkMacSystemFont, "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", 'Yu Gothic','Hiragino Kaku Gothic ProN',kozuka-gothic-pro, sans-serif,"游明朝", YuMincho;
  font-weight: normal;
  word-break: break-all;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  letter-spacing: 1px; }

a {
  transition: all .2s;
  text-decoration: none;
  color: #191919;
  cursor: pointer;
  display: block;
  transition: all .4s; }

ul, dl {
  list-style: none; }

input, textarea, select {
  appearance: none;
  line-height: 1.6; }

input, textarea {
  display: inline-block;
  border: 1px solid;
  background: none; }
  input::placeholder, textarea::placeholder {
    font-size: .9rem; }

button {
  border: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  background: none; }

hr {
  border-color: #333;
  border-width: 1px 0 0 0; }

img {
  display: block;
  width: 100%;
  height: auto; }

iframe {
  display: block;
  width: 100%; }

address {
  font-style: normal; }

.body-wrap {
  overflow: hidden; }

/* display */
.inline {
  display: inline; }

.block {
  display: block; }

.dn {
  display: none; }

.wa {
  width: auto; }

.ib {
  display: inline-block; }

/* position */
.relative {
  position: relative; }

.fixed {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  bottom: auto !important; }

/* font_common */
.xsmall {
  font-size: .75em; }

.small {
  font-size: .9rem; }

.normal {
  font-size: 1rem; }

.medium {
  font-size: 1.2rem; }

.large {
  font-size: 1.8rem; }

.xlarge {
  font-size: 2.4rem; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.left {
  text-align: left; }

.bold {
  font-weight: bold; }

.white {
  color: #fff; }

.blue {
  color: #0090D5; }

.black_n {
  color: #191919; }

.bg_blue {
  background-color: #0090D5; }

.bg_gray_r {
  background-color: #F4F6FA; }

.bg_gray_n {
  background-color: #E4EAF5; }

.bg_facebook {
  background-color: #365899; }

.bg_twitter {
  background-color: #55acee; }

.mincho {
  font-family: "游明朝", YuMincho, serif; }

.center-title {
  font-size: 1.6rem;
  text-align: center;
  color: #4492c0; }
  .center-title span {
    display: block;
    font-weight: normal;
    font-size: 1rem; }

.title_bl {
  border-left: 8px solid #4492c0;
  padding-left: 1em;
  font-size: 1.4rem;
  margin: 1em auto; }

.link {
  color: #0090D5;
  text-decoration: underline; }

.m0 {
  margin: 0; }

.l0 {
  left: 0 !important; }

.slide .lSPager.lSpg {
  display: none; }

.tablepress {
  margin: 3em auto; }
  .tablepress td {
    padding: 1em !important;
    border: 1px solid #191919 !important; }
  .tablepress .column-1 {
    width: 20%;
    text-align: center;
    vertical-align: middle; }
  .tablepress .column-2 {
    width: 80%;
    vertical-align: middle; }
  .tablepress a {
    display: inline;
    text-decoration: underline;
    color: #0090D5; }
  .tablepress ul li {
    margin-bottom: .5em; }
    .tablepress ul li:last-child {
      margin: 0; }

.slider.kv .lSSlideOuter .lSPager.lSpg {
  display: none; }

@media screen and (max-width: 767px) {
  .tablepress td {
    border: 0px solid #191919 !important; }
  .tablepress .column-1 {
    display: block;
    width: 100%;
    background-color: #F4F6FA;
    font-size: .9rem; }
  .tablepress .column-2 {
    display: block;
    width: 100%;
    font-size: .8rem; }
  .tablepress a {
    display: inline;
    text-decoration: underline;
    color: #0090D5; }
  .tablepress ul li {
    margin-bottom: .5em; }
    .tablepress ul li:last-child {
      margin: 0; } }

.triangle, .page-header::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 20px; }

.centering {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

/*
	=======================
		パンくず
	=======================
*/
.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  font-size: .9rem;
  margin-bottom: 1em; }
  .breadcrumbs .item {
    display: inline-block;
    color: #fff;
    font-size: .9rem;
    padding: 0 1em; }
    .breadcrumbs .item a {
      color: #fff;
      font-size: .9rem; }

@media screen and (max-width: 767px) {
  .breadcrumbs {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .breadcrumbs .item {
      padding: 0;
      font-size: .7rem; }
      .breadcrumbs .item:last-child {
        padding: .5em 1em; }
      .breadcrumbs .item a {
        padding: .5em 1em;
        font-size: .7rem; } }

/*
	=======================
		ボタン
	=======================
*/
.normal_button, .btn_n, .btn_b {
  width: 15em;
  padding: 1em 0;
  color: #fff; }

.btn {
  display: inline-block;
  margin: 1em auto;
  line-height: 1; }
  .btn:hover {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.35); }
    .btn:hover:active {
      box-shadow: none; }
  .btn_n {
    background-color: #70706F; }
  .btn_b {
    background-color: #191919; }

.btn_submit {
  font-size: 1rem;
  padding: 1.5em 5em;
  color: #fff;
  background: #4492c0;
  border-radius: 8px;
  transition: all .2s;
  margin: 2em auto 4em; }

/*
	=======================
		フッター
	=======================
*/
.footer {
  margin: 3em auto 0; }
  .footer .col3 {
    flex-wrap: wrap; }
    .footer .col3 .item {
      margin-bottom: 1em; }
  .footer_bottom {
    border-top: 1px;
    margin: 2em auto 0; }
    .footer_bottom .logo {
      display: inline-block;
      margin-bottom: 1em; }
      .footer_bottom .logo img {
        width: 450px;
        display: inline; }
    .footer_bottom address p {
      text-align: center;
      font-size: .9rem; }

.copyright {
  background-color: #4492c0;
  color: #fff;
  padding: .75em 0;
  line-height: 1; }

.footer-nav {
  margin: 2em auto;
  text-align: center;
  padding-top: .25em; }
  .footer-nav .menu-item {
    display: inline-block;
    margin-right: .5em; }
    .footer-nav .menu-item a {
      line-height: 1; }
      .footer-nav .menu-item a::before {
        content: ">>";
        margin-right: .25em; }

.footer-banner2 {
  flex-wrap: wrap; }
  .footer-banner2 .item {
    flex-basis: 32%;
    margin-right: 2%;
    margin-bottom: 2%; }
    .footer-banner2 .item:nth-child(3n) {
      margin-right: 0; }

@media screen and (max-width: 1024px) {
  .footer {
    margin-top: 0; }
    .footer .col3 {
      margin-bottom: -1em; } }

@media screen and (max-width: 767px) {
  .footer-banner1 {
    flex-wrap: wrap;
    margin-top: 0 !important; }
    .footer-banner1 .item {
      flex-basis: 100% !important; }
    .footer-banner1 .facebook {
      margin-bottom: 1em; }
  .footer-banner2 {
    margin-bottom: 0; }
    .footer-banner2 .item {
      flex-basis: 48% !important; }
  .copyright {
    font-size: .7rem; }
  .footer_bottom {
    padding: 1em;
    margin-top: 1em; }
    .footer_bottom .logo img {
      width: 100%; }
    .footer_bottom address p {
      font-size: .8rem;
      display: flex;
      justify-content: center; }
  .footer-nav {
    margin: 1em auto 0;
    text-align: left; }
    .footer-nav .menu-item a {
      font-size: .8rem; } }

/*
	=======================
		ヘッダー
	=======================
*/
.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0;
  order: 5; }
  .header-inner .item {
    text-align: center;
    color: #0090D5;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 1px; }
    .header-inner .item:nth-child(1) {
      order: 3; }
    .header-inner .item:nth-child(2) {
      order: 1; }
    .header-inner .item:nth-child(3) {
      order: 2; }
    .header-inner .item:nth-child(4) {
      order: 4; }
    .header-inner .item:nth-child(5) {
      order: 5; }
    .header-inner .item span {
      font-size: .8rem;
      font-weight: normal; }
  .header-inner .logo {
    width: 40%; }

@media screen and (max-width: 767px) {
  .mobile-header {
    width: 80%;
    margin: auto; }
  .mobile-nav {
    position: fixed;
    top: 0;
    left: calc(-100vw - 1em);
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    transition: all .4s; }
    .mobile-nav > .item {
      padding: 1.5em; }
      .mobile-nav > .item .logo {
        padding: 1em 0;
        border-bottom: 1px dotted #0090D5; }
      .mobile-nav > .item .inner {
        margin: 1.5em auto 2.5em; }
        .mobile-nav > .item .inner li {
          margin-bottom: 1em; }
          .mobile-nav > .item .inner li a {
            padding: .5em 0;
            position: relative;
            font-size: 1.2rem;
            color: #4492c0;
            font-weight: bold; }
            .mobile-nav > .item .inner li a:hover::after {
              width: 100%; }
            .mobile-nav > .item .inner li a::after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 1em;
              height: 1px;
              background-color: #4492c0;
              transition: all .4s; }
  .mobile-menu {
    position: fixed;
    top: 1%;
    right: 0;
    font-size: 2rem;
    line-height: 0;
    padding: .25em;
    z-index: 99; }
    .mobile-menu i {
      color: #4492c0; }
  .menu-close {
    font-size: 2rem;
    line-height: 1;
    padding: .25em;
    position: absolute;
    top: 1%;
    right: 0; }
    .menu-close i {
      color: #4492c0; } }

/*
  =======================
    アイコン
  =======================
*/
.icon {
  position: relative;
  top: 2px;
  margin-right: .15em; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?uhmk8h");
  src: url("../fonts/icomoon.eot?uhmk8h#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?uhmk8h") format("truetype"), url("../fonts/icomoon.woff?uhmk8h") format("woff"), url("../fonts/icomoon.svg?uhmk8h#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-menu:before {
  content: "\e926"; }

.icon-edit:before {
  content: "\e923"; }

.icon-reserve:before {
  content: "\e924"; }

.icon-list:before {
  content: "\e925"; }

.icon-swipe:before {
  content: "\e922"; }

.icon-replay:before {
  content: "\e90f"; }

.icon-send:before {
  content: "\e910"; }

.icon-newlabel .path1:before {
  content: "\e911";
  color: black;
  opacity: 0.35; }

.icon-newlabel .path2:before {
  content: "\e91c";
  margin-left: -0.998046875em;
  color: black; }

.icon-newlabel .path3:before {
  content: "\e91d";
  margin-left: -0.998046875em;
  color: black; }

.icon-newlabel .path4:before {
  content: "\e91e";
  margin-left: -0.998046875em;
  color: black; }

.icon-newlabel .path5:before {
  content: "\e91f";
  margin-left: -0.998046875em;
  color: white; }

.icon-newlabel .path6:before {
  content: "\e920";
  margin-left: -0.998046875em;
  color: white; }

.icon-newlabel .path7:before {
  content: "\e921";
  margin-left: -0.998046875em;
  color: white; }

.icon-arrow_down:before {
  content: "\e900"; }

.icon-arrow_fp:before {
  content: "\e901"; }

.icon-arrow_left:before {
  content: "\e902"; }

.icon-arrow_lp:before {
  content: "\e903"; }

.icon-arrow_right:before {
  content: "\e904"; }

.icon-arrow_up:before {
  content: "\e905"; }

.icon-add_circle:before {
  content: "\e906"; }

.icon-add:before {
  content: "\e907"; }

.icon-border_wave:before {
  content: "\e908"; }

.icon-calender:before {
  content: "\e909"; }

.icon-cancel:before {
  content: "\e90a"; }

.icon-check:before {
  content: "\e90b"; }

.icon-close:before {
  content: "\e90c"; }

.icon-delete:before {
  content: "\e90d"; }

.icon-hearing:before {
  content: "\e90e"; }

.icon-link:before {
  content: "\e912"; }

.icon-lock:before {
  content: "\e913"; }

.icon-map:before {
  content: "\e914"; }

.icon-person_add:before {
  content: "\e915"; }

.icon-pointer:before {
  content: "\e916"; }

.icon-search:before {
  content: "\e917"; }

.icon-sort:before {
  content: "\e918"; }

.icon-star-h:before {
  content: "\e919"; }

.icon-star:before {
  content: "\e91a"; }

.icon-time:before {
  content: "\e91b"; }

/*
	=======================
		レイアウト
	=======================
*/
.wrapper {
  max-width: 1400px;
  margin: auto; }
  .wrapper1000 {
    max-width: 1000px;
    margin: auto; }

.wrap {
  margin: 3em auto; }

@media screen and (max-width: 1024px) {
  .wrapper1000 {
    padding: 1em; } }

/*
	=======================
		table
	=======================
*/
.table {
  width: 100%;
  border-collapse: collapse; }
  .table th, .table td {
    padding: 1em; }
  .table th {
    width: 20%;
    text-align: center; }
  .table td {
    width: 80%;
    line-height: 1.8; }
    .table td ul li {
      margin-bottom: 1em; }
    .table td ul :last-child {
      margin: 0; }

@media screen and (max-width: 767px) {
  .table {
    border: 1px solid #E4EAF5;
    border-top: none; }
    .table th, .table td {
      display: block;
      width: 100%; }
    .table th {
      border-top: 1px solid #E4EAF5;
      border-bottom: 1px solid #E4EAF5;
      border-left: none;
      border-right: none; }
    .table td {
      border: none;
      font-size: .9rem; }
      .table td ul li {
        margin-bottom: .5em; } }

/*
	=======================
		カードレイアウト / 4列
	=======================
*/
.cl_col4 {
  display: flex;
  flex-wrap: wrap;
  margin: 2em -.5em; }

.card {
  width: 25%;
  margin-bottom: 1em;
  position: relative;
  transition: none; }
  .card:hover .inner::after {
    width: calc(100% - 1em); }
  .card:hover .cnt .title {
    color: #0090D5; }
  .card .inner {
    margin: 0 .5em;
    height: 100%; }
    .card .inner::after {
      content: "";
      display: block;
      width: 2em;
      height: 1px;
      background-color: #0090D5;
      position: absolute;
      bottom: 0;
      left: .5em;
      transition: all .4s; }
  .card .cnt {
    position: relative;
    padding: 1em 0; }
    .card .cnt .title {
      font-size: 1.2rem;
      margin-bottom: .25em; }
    .card .cnt .excerpt {
      font-size: .9rem;
      margin: .5em auto; }
    .card .cnt .time {
      font-size: .8rem; }

@media screen and (max-width: 1024px) {
  .cl_col4 {
    margin: 0em -.5em; } }

@media screen and (max-width: 767px) {
  .cl_col4 {
    margin: 0 auto; }
  .card {
    width: 100%; }
    .card .inner {
      display: flex;
      justify-content: space-between;
      margin: 0; }
      .card .inner::after {
        left: 0; }
    .card .thumb {
      flex-basis: 35%; }
    .card .cnt {
      flex-basis: 62%; }
      .card .cnt .title {
        font-size: 1rem; }
      .card .cnt .excerpt {
        font-size: .7rem;
        margin: .25em auto;
        line-height: 1.4; } }

/*
	=======================
		カードレイアウト / ベース
	=======================
*/
.between, .col2, .col3, .col4 {
  justify-content: space-between;
  margin: 1em auto; }

.layout_f {
  display: flex; }

.col2 .item {
  flex-basis: 49%; }

.col3 .item {
  flex-basis: 32.5%; }

.col4 .item {
  flex-basis: 24%; }

.col4_25 .item {
  flex-basis: 25%; }

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.88); }
  .modal .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 999; }

.overlay {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.88);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; }

.trigger {
  cursor: pointer; }

.close {
  position: absolute;
  right: -31px;
  top: -10px;
  z-index: 999;
  color: #fff;
  padding: .5em;
  cursor: pointer; }
  .close .icon-cancel {
    font-size: 2.4rem;
    line-height: 0; }

@media screen and (max-width: 1024px) {
  .col4_25 .item {
    flex-basis: 33.33%; } }

@media screen and (max-width: 767px) {
  .col4_25 .item {
    flex-basis: 100%; } }

/*
	=======================
		ページ / misawajc
	=======================
*/
.about {
  margin: 3em -2% 3em 0em;
  flex-wrap: wrap;
  justify-content: baseline; }
  .about .item {
    position: relative;
    text-align: center;
    width: 23%;
    margin-right: 2%;
    margin-bottom: 1em; }
    .about .item-wrap {
      color: #fff;
      border: 1px solid;
      border-radius: 50%;
      background-color: #0090D5; }
      .about .item-wrap .inner {
        width: 100%; }
        .about .item-wrap .inner .more {
          color: #fff; }
          .about .item-wrap .inner .more i {
            font-size: 1.3rem;
            position: relative;
            top: 4px; }
          .about .item-wrap .inner .more .icon-arrow_right {
            margin-left: -.5em; }
          .about .item-wrap .inner .more .icon-arrow_left {
            margin-right: -.5em; }
        .about .item-wrap .inner .title {
          margin-bottom: 1em;
          font-size: 1.2rem; }
          .about .item-wrap .inner .title span {
            font-size: 1rem; }
      .about .item-wrap:hover {
        background-color: #fff;
        border-color: #0090D5; }
        .about .item-wrap:hover .inner .title {
          color: #0090D5; }
        .about .item-wrap:hover .inner .more {
          color: #0090D5; }
          .about .item-wrap:hover .inner .more .icon-arrow_right {
            right: -.5em; }
          .about .item-wrap:hover .inner .more .icon-arrow_left {
            left: -.5em; }

@media screen and (max-width: 1024px) {
  .about .item .inner .title {
    font-size: .9rem; }
    .about .item .inner .title span {
      font-size: .8rem; }
  .about .item .inner .more {
    font-size: .8rem; } }

@media screen and (max-width: 767px) {
  .about .item {
    width: 48%; }
    .about .item-wrap .inner .title {
      margin-bottom: 0; } }

/*
	=======================
		ページ / admission
	=======================
*/
.admission h2 {
  margin-bottom: 1em; }

.admission p {
  margin: 2em auto;
  line-height: 2; }

.admission ol {
  margin-left: 2em; }
  .admission ol li {
    margin: 1em auto;
    line-height: 1.8; }

.admission > #contact {
  padding-top: 2.5em;
  margin-top: -2.5em; }
  .admission > #contact > h3 {
    margin-top: 0; }

.admission .mw_wp_form .form-wrap {
  border-bottom: 1px solid;
  margin-bottom: 2em; }
  .admission .mw_wp_form .form-wrap dl {
    display: table;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    width: 100%; }
    .admission .mw_wp_form .form-wrap dl:last-child {
      border-bottom: 1px solid; }
    .admission .mw_wp_form .form-wrap dl dt, .admission .mw_wp_form .form-wrap dl dd {
      display: table-cell;
      padding: 1em;
      vertical-align: middle; }
    .admission .mw_wp_form .form-wrap dl dt {
      width: 30%;
      border-right: 1px solid; }
    .admission .mw_wp_form .form-wrap dl dd {
      width: 70%; }
      .admission .mw_wp_form .form-wrap dl dd input, .admission .mw_wp_form .form-wrap dl dd select, .admission .mw_wp_form .form-wrap dl dd textarea {
        width: 100%;
        appearance: none;
        padding: .5em 1em;
        background: #fff;
        font-size: 1rem;
        border-color: #ccc; }
      .admission .mw_wp_form .form-wrap dl dd select {
        cursor: pointer;
        font-size: .9rem;
        line-height: 1.2; }
      .admission .mw_wp_form .form-wrap dl dd textarea {
        padding: 1em; }

.admission .mw_wp_form .agreement {
  appearance: checkbox !important; }

.admission .mw_wp_form .horizontal-item {
  display: inline-block;
  margin-right: 1em; }

.admission .mw_wp_form .mwform-radio-field input {
  appearance: radio !important;
  width: auto !important;
  position: relative;
  top: -2px;
  margin-right: .5em !important; }

.admission .mw_wp_form .mwform-tel-field {
  display: inline-flex;
  align-items: center;
  margin: 0 -1em;
  width: 100%; }
  .admission .mw_wp_form .mwform-tel-field .tel {
    flex-basis: 30%;
    margin-left: 1em;
    margin-right: 1em; }

.admission .mw_wp_form .required, .admission .mw_wp_form .optional {
  font-size: .8rem;
  color: #fff;
  padding: .5em 1em .35em;
  line-height: 0;
  margin-right: 1em; }

.admission .mw_wp_form .required {
  background: #ED343B; }

.admission .mw_wp_form .optional {
  background: #0090D5; }

.admission .mw_wp_form .agreement-wrap p {
  margin: .5em auto; }
  .admission .mw_wp_form .agreement-wrap p a {
    display: inline;
    text-decoration: underline;
    color: #0090D5; }

.admission .mw_wp_form .wp-form-inner {
  position: relative; }
  .admission .mw_wp_form .wp-form-inner::after {
    content: "";
    position: absolute;
    top: 50%;
    right: .5rem;
    pointer-events: none;
    transform: translateY(-50%);
    width: 20px;
    height: 80%;
    background: url(../images/icon/ic_arrow_drop_down.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }

.mwform-checkbox-field input {
  appearance: checkbox !important;
  width: auto !important;
  position: relative;
  top: 2px; }

.mwform-checkbox-field label {
  display: flex; }

@media screen and (max-width: 767px) {
  .admission .mw_wp_form .form-wrap dl {
    display: block; }
    .admission .mw_wp_form .form-wrap dl dt, .admission .mw_wp_form .form-wrap dl dd {
      display: block;
      width: 100%; }
    .admission .mw_wp_form .form-wrap dl dt {
      border-right: none;
      border-bottom: 1px solid; }
  .admission .mw_wp_form .mwform-tel-field {
    margin: 0 -.5em;
    width: 106%; }
    .admission .mw_wp_form .mwform-tel-field .tel {
      flex-basis: 30%;
      margin-left: .5em;
      margin-right: .5em;
      font-size: .9rem; } }

/*
	=======================
		ページ / document
	=======================
*/
.document .table {
  margin: 1em auto; }
  .document .table thead {
    background: #F4F6FA; }
  .document .table th {
    border: 1px solid #E4EAF5; }
    .document .table th:first-child {
      text-align: left; }
  .document .table td {
    border: 1px solid #E4EAF5; }
  .document .table .dc_name {
    width: 60%; }
  .document .table .dc_format {
    width: 15%;
    text-align: center;
    text-transform: uppercase; }
  .document .table .dc_size {
    width: 15%;
    text-align: center; }

@media screen and (max-width: 767px) {
  .document .table thead th {
    font-size: .85rem;
    padding: .5em .5em; }
    .document .table thead th:first-child {
      width: 40%; }
    .document .table thead th:not(:first-child) {
      width: 20%; }
  .document .table th, .document .table td {
    display: table-cell; }
  .document .table td {
    font-size: .7rem; }
  .document .table .dc_name a {
    font-size: .9rem; } }

/*
	=======================
		ページ / faq
	=======================
*/
.faq p {
  line-height: 1.6;
  margin: 2em auto; }

.faq-wrap {
  margin-bottom: 4em; }

/*
	=======================
		front-page.php トップページ
	=======================
*/
.top_info {
  margin: 2em; }
  .top_info .en-title {
    font-size: 2.4rem;
    text-align: center; }
  .top_info .title {
    font-weight: normal;
    font-size: 1rem;
    text-align: center; }

.slogan {
  background-color: #0090D5;
  margin: 3em auto 0; }
  .slogan .item {
    width: 32em;
    margin: auto;
    padding: 7em 0; }
    .slogan .item img {
      margin-bottom: 1em; }

.member {
  flex-wrap: wrap; }
  .member .item {
    margin-bottom: 1em; }
  .member .top_info {
    margin: 0; }
  .member_top .en-title, .member_top .title {
    text-shadow: 3px 2px 12px rgba(0, 0, 0, 0.62); }

@media screen and (max-width: 767px) {
  .top_info {
    margin: 1em; }
    .top_info .en-title {
      font-size: 1.6em; }
    .top_info .title {
      font-size: .8rem; }
  .slogan {
    margin-bottom: 1em;
    margin-top: 1em; }
    .slogan .item {
      width: 100%;
      padding: 5em 2em; }
  .member_top .top_info {
    margin: 0; } }

/*
	=======================
		ページ / member
	=======================
*/
.member {
  margin: 2em auto; }
  .member .item-inner {
    padding: 1em; }
    .member .item-inner .position {
      margin-bottom: .5em;
      font-size: .9rem; }
    .member .item-inner .title {
      font-size: 1.2rem; }
    .member .item-inner .ruby {
      font-weight: 100;
      font-size: .9rem; }

.member_col {
  background-color: #000;
  color: #fff;
  max-width: 1200px;
  margin: auto;
  padding: 2em; }
  .member_col .item-l {
    flex-basis: 70%;
    padding-right: 2em; }
    .member_col .item-l .table {
      font-size: .9rem; }
      .member_col .item-l .table th {
        width: 12%;
        padding: 1em 0; }
      .member_col .item-l .table td {
        width: 80%;
        text-align: left; }
        .member_col .item-l .table td .cfs-hyperlink {
          color: #fff;
          text-decoration: underline; }
  .member_col .item-r {
    flex-basis: 30%; }
  .member_col2_title {
    text-align: left;
    padding-bottom: 1em;
    border-bottom: 1px solid;
    margin-bottom: 1em; }
    .member_col2_title .position {
      margin-bottom: .25em; }
    .member_col2_title .title {
      font-weight: bold;
      font-size: 1.4rem; }
    .member_col2_title .ruby {
      font-size: .9rem; }

@media screen and (max-width: 1024px) {
  .member_col {
    max-width: 90%; } }

@media screen and (max-width: 767px) {
  .member .item-inner {
    padding: .5em; }
    .member .item-inner .position {
      margin-bottom: .5em; }
    .member .item-inner .title {
      font-size: 1.4rem; }
    .member .item-inner .ruby {
      font-weight: 100; }
  .member_col {
    overflow: auto;
    flex-wrap: wrap;
    order: 3; }
    .member_col .item-r {
      order: 1;
      flex-basis: 100%;
      margin-bottom: 1em; }
    .member_col .item-l {
      order: 2;
      flex-basis: 100%;
      padding: 0; }
      .member_col .item-l .member_col2_title {
        text-align: center;
        margin-bottom: 2em; }
      .member_col .item-l .table th, .member_col .item-l .table td {
        display: block;
        width: 100%; }
    .member_col .close {
      position: fixed !important;
      right: initial !important;
      left: 0 !important; }
    .member_col2_title .position {
      font-size: .8rem; }
    .member_col2_title .title {
      font-weight: bold;
      font-size: 1.4rem; } }

/*
	=======================
		ページ / opinion
	=======================
*/
.opini_img {
  justify-content: space-between; }
  .opini_img .item {
    width: 49%; }

@media screen and (max-width: 767px) {
  .opini_img {
    margin-bottom: 2em;
    display: block; }
    .opini_img .item {
      width: 100%; } }

/*
	=======================
		ページ / outline
	=======================
*/
.outline h2 {
  border: none !important;
  padding: 0 !important; }

.outline .map {
  margin: 4em auto; }

.plan {
  margin-bottom: 4em;
  flex-wrap: wrap; }
  .plan .item ul li {
    margin-bottom: 1em; }

@media screen and (max-width: 1024px) {
  .outline .map {
    margin: 2em auto; }
  .plan {
    display: block;
    margin-bottom: 2em; }
    .plan .item {
      margin-bottom: 1em; } }

/*
	=======================
		ページ共通
	=======================
*/
.page-header {
  background-color: #4492c0;
  position: relative;
  padding: 1em 0;
  margin-bottom: 4em; }
  .page-header::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%);
    border-color: #4492c0 transparent transparent transparent; }
  .page-header .title {
    text-align: center;
    color: #fff;
    padding: 1em 0;
    font-size: 1.6rem; }
    .page-header .title span {
      font-size: 1rem;
      font-weight: normal; }

.page-content h1, .page-content h3, .page-content h4, .page-content h5, .page-content h6 {
  font-size: 1.4rem; }

.page-content h2 {
  border-left: 8px solid #0090D5;
  padding-left: .75em;
  margin: 1em auto;
  line-height: 1.8; }

.page-content p {
  margin: 1em auto;
  line-height: 2; }

@media screen and (max-width: 767px) {
  .page-header {
    margin-bottom: 1em; }
    .page-header .title {
      font-size: 1.2rem;
      padding: 1em; }
  .page-content h2 {
    font-size: 1.2rem; } }

/*
	=======================
		ページ　/ principle
	=======================
*/
.principle {
  margin: 2em auto 4em; }
  .principle_section {
    border: 1px solid #4492c0;
    padding: .5em; }
    .principle_section .inner {
      border: 1px solid #4492c0;
      padding: 1.5em; }
      .principle_section .inner .item .title {
        color: #4492c0;
        font-size: 1.7rem;
        margin-bottom: .5em; }
      .principle_section .inner .item .txt {
        line-height: 2;
        font-size: .9rem; }
        .principle_section .inner .item .txt span {
          display: block;
          padding-left: 1em; }
      .principle_section .inner .item-l {
        flex-basis: 70%; }
      .principle_section .inner .item-r {
        flex-basis: 30%; }
  .principle_section2 {
    margin: 3em auto; }
    .principle_section2 .item {
      padding: 1em;
      border: 1px solid #4492c0; }
      .principle_section2 .item .title {
        color: #4492c0;
        font-size: 1.6rem;
        margin-top: 0em;
        margin-bottom: .5em; }

@media screen and (max-width: 1024px) {
  .principle_section .inner .item-l {
    flex-basis: 65%;
    padding-right: 1em; }
  .principle_section .inner .item-r {
    flex-basis: 35%; } }

@media screen and (max-width: 767px) {
  .principle {
    margin-bottom: 0; }
  .principle_section .inner {
    display: block; }
    .principle_section .inner .item-l {
      margin-bottom: 1em; }
  .principle_section2 {
    display: block;
    margin: 1em auto; }
    .principle_section2 .item:first-child {
      margin-bottom: 1em; } }

/*
	=======================
		ページ / スケジュール
	=======================
*/
.tablepress-id-2 td {
  border-color: #fff !important; }

.tablepress-id-2 .column-1 {
  width: 10%;
  background-color: #8b8f85;
  color: #fff; }

.tablepress-id-2 .row-1 .column-2, .tablepress-id-2 .row-8 .column-2, .tablepress-id-2 .row-14 .column-2 {
  background-color: #d2e8d4; }

.tablepress-id-2 .row-2 .column-2, .tablepress-id-2 .row-7 .column-2, .tablepress-id-2 .row-11 .column-2 {
  background-color: #4492c0;
  color: #fff; }

.tablepress-id-2 .row-3 .column-2, .tablepress-id-2 .row-13 .column-2 {
  background-color: #c7e9e8; }

.tablepress-id-2 .row-4 .column-2, .tablepress-id-2 .row-5 .column-2, .tablepress-id-2 .row-6 .column-2 {
  background-color: #f8e2be; }

.tablepress-id-2 .row-9 .column-2, .tablepress-id-2 .row-10 .column-2, .tablepress-id-2 .row-12 .column-2 {
  background-color: #fadad4; }

@media screen and (max-width: 767px) {
  .tablepress-id-2 {
    margin-top: 2em; }
    .tablepress-id-2 th, .tablepress-id-2 td {
      width: 100% !important; } }

/*
	=======================
		投稿ページ
	=======================
*/
.single_thumb {
  margin: 2em auto; }

.single > h1 {
  font-size: 2rem;
  margin: 2em auto 1em;
  padding: .5em 1em;
  border-radius: 4px;
  background: #F4F6FA; }

.single > h2 {
  font-size: 1.6rem;
  margin: 2em auto 1em; }
  .single > h2::after {
    content: "";
    display: block;
    width: 3em;
    height: 1px;
    background-color: #191919;
    margin-top: .5em; }

.single > h3, .single > h4, .single > h5, .single > h6 {
  font-size: 1.2rem;
  margin: 2em auto 1em; }

.single > p {
  margin: 2em auto;
  line-height: 2; }

@media screen and (max-width: 767px) {
  .single > p {
    font-size: 16px; } }
