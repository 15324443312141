/*
	=======================
		front-page.php トップページ
	=======================
*/
.top_info {
	margin: 2em;
	.en-title {
		font-size: 2.4rem;
		text-align: center;
	}
	.title {
		font-weight: normal;
		font-size: 1rem;
		text-align: center;
	}
}
.slogan {
	background-color: $blue;
	margin: 3em auto 0;
	.item {
		width: 32em;
		margin: auto;
		padding: 7em 0;
		img {
			margin-bottom: 1em;
		}
	}
}
.member {
	flex-wrap: wrap;
	.item {
		margin-bottom: 1em;
	}
	.top_info {
		margin: 0;
	}
	&_top {
		.en-title,.title {
			text-shadow: 3px 2px 12px rgba(0, 0, 0, 0.62);
		}
	}
}
@include mq {
	.top_info {
		margin: 1em;
		.en-title {
			font-size: 1.6em;
		}
		.title {
			font-size: .8rem;
		}
	}
	.slogan {
		margin-bottom: 1em;
		margin-top: 1em;
		.item {
			width: 100%;
			padding: 5em 2em;
		}
	}
	.member_top {
		.top_info {
			margin: 0;
		}
	}
}