/*
	=======================
		ページ / misawajc
	=======================
*/
.about {
	margin: 3em -2% 3em 0em;
	flex-wrap: wrap;
	justify-content: baseline;
	.item {
		position: relative;
		text-align: center;
		width: 23%;
		margin-right: 2%;
		margin-bottom: 1em;
		&-wrap {
			color: #fff;
			border: 1px solid;
			border-radius: 50%;
			background-color: $blue;
			.inner {
				width: 100%;
				.more {
					color: #fff;
					i {
						font-size: 1.3rem;
						position: relative;
						top: 4px;
						// transition: all .4s;
					}
					.icon-arrow_right {
						margin-left: -.5em;
					}
					.icon-arrow_left {
						margin-right: -.5em;
					}
				}
				.title {
					margin-bottom: 1em;
					font-size: 1.2rem;
					span {
						font-size: 1rem;
					}
				}
			}
			&:hover {
				background-color: #fff;
				border-color: $blue;
				.inner {
					.title {
						color: $blue;
					}
					.more {
						color: $blue;
						.icon-arrow_right {
							right: -.5em;
						}
						.icon-arrow_left {
							left: -.5em;
						}
					}
				}
			}
		}
	}
}
@include mq(tab) {
	.about {
		.item {
			.inner {
				.title {
					font-size: .9rem;
					span {
						font-size: .8rem;
					}
				}
				.more {
					font-size: .8rem;
				}
			}
		}
	}
}
@include mq {
	.about {
		.item {
			width: 48%;
			&-wrap {
				.inner {
					.title {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}