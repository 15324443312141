/*
	=======================
		table
	=======================
*/
.table {
	width: 100%;
	border-collapse: collapse;
	th,td {
		padding: 1em;
	}
	th {
		width: 20%;
		text-align: center;
	}
	td {
		width: 80%;
		line-height: 1.8;
		ul {
			li {
				margin-bottom: 1em;
			}
			:last-child {
				margin: 0;
			}
		}
	}
}

@include mq {
	.table {
		border: 1px solid $gray_n;
		border-top: none;
		th,td {
			display: block;
			width: 100%;
		}
		th {
			border-top: 1px solid $gray_n;
			border-bottom: 1px solid $gray_n;
			border-left: none;
			border-right: none;
		}
		td {
			border: none;
			font-size: .9rem;
			ul {
				li {
					margin-bottom: .5em;
				}
			}
		}
	}
}