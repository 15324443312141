/*
	=======================
		カードレイアウト / ベース
	=======================
*/
.between {
	//extend
	justify-content: space-between;
	margin: 1em auto;
}
.layout_f {
	display: flex;
}
.col2 {
	@extend .between;
	.item {
		flex-basis: 49%;
	}
}
.col3 {
	@extend .between;
	.item {
		flex-basis: 32.5%;
	}
}
.col4 {
	@extend .between;
	.item {
		flex-basis: 24%;
	}
}
.col4_25 {
	.item {
		flex-basis: 25%;
	}
}

.modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0, 0, 0, 0.88);
	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		z-index: 999;
	}
}
.overlay {
	display: none;
	position: fixed;
	background: rgba(0, 0, 0, 0.88);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
}
.trigger {
	cursor: pointer;
}
.close {
	position: absolute;
	right: -31px;
	top: -10px;
	z-index: 999;
	color: #fff;
	padding: .5em;
	cursor: pointer;
	.icon-cancel {
		font-size: 2.4rem;
		line-height: 0;
	}
}
@include mq(tab) {
	.col4_25 {
		.item {
			flex-basis: 33.33%;
		}
	}
}
@include mq {
	.col4_25 {
		.item {
			flex-basis: 100%;
		}
	}
}