/*
	=======================
		フッター
	=======================
*/
.footer {
	margin: 3em auto 0;
	.col3 {
		flex-wrap: wrap;
		.item {
			margin-bottom: 1em;
		}
	}
	&_bottom {
		border-top: 1px;
		margin: 2em auto 0;
		.logo {
			display: inline-block;
			margin-bottom: 1em;
			img {
				width: 450px;
				display: inline;
			}
		}
		address {
			p {
				text-align: center;
				font-size: .9rem;
			}
		}
	}
}
.copyright {
	background-color: $blue_r;
	color: #fff;
	padding: .75em 0;
	line-height: 1;
}
.footer-nav {
	margin: 2em auto;
	text-align: center;
	padding-top: .25em;
	.menu-item {
		display: inline-block;
		margin-right: .5em;
		a {
			line-height: 1;
			&::before {
				content: ">>";
				margin-right: .25em;
			}
		}
	}
}

.footer-banner2 {
	flex-wrap: wrap;
	.item {
		flex-basis: 32%;
		margin-right: 2%;
		margin-bottom: 2%;
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}
@include mq(tab) {
	.footer {
		margin-top: 0;
		.col3 {
			margin-bottom: -1em;
		}
	}
}
@include mq {
	.footer-banner1 {
		flex-wrap: wrap;
		margin-top: 0 !important;
		.item {
			flex-basis: 100% !important;
		}
		.facebook {
			margin-bottom: 1em;
		}
	}
	.footer-banner2 {
		margin-bottom: 0;
		.item {
			flex-basis: 48% !important;
		}
	}
	.copyright {
		font-size: .7rem;
	}
	.footer_bottom {
		padding: 1em;
		margin-top: 1em;
		.logo {
			img {
				width: 100%;
			}
		}
		address {
			p {
				font-size: .8rem;
				display: flex;
				justify-content: center;
			}
		}
	}
	.footer-nav {
		margin: 1em auto 0;
		text-align: left;
		.menu-item {
			a {
				font-size: .8rem;
			}
		}
	}
}
