/*
	=======================
		ページ共通
	=======================
*/
.page {
	&-header {
		background-color: $blue_r;
		position: relative;
		padding: 1em 0;
		margin-bottom: 4em;
		&::after {
			content: "";
			@extend .triangle;
			position: absolute;
			bottom: -20px;
			left: 50%;
			transform: translate(-50%);
			border-color: $blue_r transparent transparent transparent;
		}
		.title {
			text-align: center;
			color: #fff;
			padding: 1em 0;
			font-size: 1.6rem;
			span {
				font-size: 1rem;
				font-weight: normal;
			}
		}
	}
	&-content {
		h1,h3,h4,h5,h6 {
			font-size: 1.4rem;
		}
		h2 {
			border-left: 8px solid $blue;
			padding-left: .75em;
			margin: 1em auto;
			line-height: 1.8;
		}
		p {
			margin: 1em auto;
			line-height: 2;
		}
	}
}
@include mq {
	.page {
		&-header {
			margin-bottom: 1em;
			.title {
				font-size: 1.2rem;
				padding: 1em;
			}
		}
		&-content {
			h2 {
				font-size: 1.2rem;
			}
		}
	}
}