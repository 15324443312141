// for medeaqueryies
@mixin mq($breakpoint: sp) {
	@if $breakpoint == sp {
		@media screen and (max-width: 767px) {
			@content;
		}
	} @else if $breakpoint == tab {
		@media screen and (max-width: 1024px) {
			@content;
		}
	} @else if $breakpoint == tab_w {
		@media screen and (max-width: 1200px) {
			@content;
		}
	}
	// } @else if $breakpoint == large {
	// 	@media screen and (max-width: 1200px) {
	// 		@content;
	// 	}
	// } @else if $breakpoint == xlarge {
	// 	@media screen and (max-width: 1280px) {
	// 		@content;
	// 	}
	// }
}

// // background color
// @mixin bgclr($color: #fff) {
// 	background-color: $color;
// }