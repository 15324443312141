/*
	=======================
		パンくず
	=======================
*/
.breadcrumbs {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	color: #fff;
	font-size: .9rem;
	margin-bottom: 1em;
	.item {
		display: inline-block;
		color: #fff;
		font-size: .9rem;
		padding: 0 1em;
		&:first-child {

		}
		a {
			color: #fff;
			font-size: .9rem;
		}
	}
}
@include mq {
	.breadcrumbs {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		.item {
			padding: 0;
			font-size: .7rem;
			&:last-child {
				padding: .5em 1em;
			}
			a {
				padding: .5em 1em;
				font-size: .7rem;
			}
		}
	}
}