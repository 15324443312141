/*
	=======================
		リセット | 共通設定
	=======================
*/
*,*::before,*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	// transition: all .4s;
}
html {
	width: 100%;
	height: 100%;
}
body {
	margin: 0;
	font-size: 16px;
	color: $black_n;
	font-family: 'Segoe UI',Helvetica,'メイリオ', Meiryo,-apple-system, BlinkMacSystemFont, "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", 'Yu Gothic','Hiragino Kaku Gothic ProN',kozuka-gothic-pro, sans-serif,"游明朝", YuMincho;
	font-weight: normal;
	word-break: break-all;
	line-height: 1.6;
	-webkit-text-size-adjust: 100%;
	letter-spacing: 1px;
}
a {
	transition: all .2s;
	text-decoration: none;
	color: $black_n;
	cursor: pointer;
	display: block;
	transition: all .4s;
}
ul,dl {
	list-style: none;
}
dt,dd,th,td,ol {
}
input,textarea,select {
	appearance: none;
	line-height: 1.6;
}
input,textarea {
	display: inline-block;
	border: 1px solid;
	background: none;
	&::placeholder {
		font-size: .9rem;
	}
}
button {
	border: 0;
	outline: none;
	appearance: none;
	cursor: pointer;
	background: none;
}
hr {
	border-color: #333;
	border-width: 1px 0 0 0;
}
img {
	display: block;
	width: 100%;
	height: auto;
}
iframe {
	display: block;
	width: 100%;
}
address {
	font-style: normal;
}

.body-wrap {
	overflow: hidden;
}

/* display */
.inline {
	display: inline;
}
.block {
	display: block;
}
.dn {
	display: none;
}
.wa {
	width: auto;
}
.ib {
	display: inline-block;
}

/* position */
.relative {
	position: relative;
}
.fixed {
	position: fixed !important;
	top: 0 !important;
	left: 0 !important;
	bottom: auto !important;
}

/* font_common */
.xsmall {
	font-size: .75em;
}
.small {
	font-size: .9rem;
}
.normal {
	font-size: 1rem;
}
.medium {
	font-size: 1.2rem;
}
.large {
	font-size: 1.8rem;
}
.xlarge {
	font-size: 2.4rem;
}
.center {
	text-align: center;
}
.right {
	text-align: right;
}
.left {
	text-align: left;
}
.bold {
	font-weight: bold;
}

//color
.white {
	color: #fff;
}
.blue {
	color: $blue;
}
.black_n {
	color: $black_n;
}
// background color
.bg_blue {
	background-color: $blue;
}
.bg_gray_r {
	background-color: $gray_r;
}
.bg_gray_n {
	background-color: $gray_n;
}
.bg_facebook {
	background-color: $facebook;
}
.bg_twitter {
	background-color: $twitter;
}


.mincho {
	font-family: "游明朝", YuMincho, serif;
}

.center-title {
	font-size: 1.6rem;
	text-align: center;
	color: $blue_d;
	span {
		display: block;
		font-weight: normal;
		font-size: 1rem;
	}
}
.title_bl {
	border-left: 8px solid $blue_d;
	padding-left: 1em;
	font-size: 1.4rem;
	margin: 1em auto;
}
.link {
	color: $blue;
	text-decoration: underline;
}

.m0 {
	margin: 0;
}
.l0 {
	left: 0 !important;
}

.slide {
	.lSPager.lSpg {
		display: none;
	}
}

// tablepress / スケジュール
.tablepress {
	margin: 3em auto;
	td {
		padding: 1em !important;
		border: 1px solid $black_n !important;
	}
	.column-1 {
		width: 20%;
		text-align: center;
		vertical-align: middle;
	}
	.column-2 {
		width: 80%;
		vertical-align: middle;
	}
	a {
		display: inline;
		text-decoration: underline;
		color: $blue;
	}
	ul {
		li {
			margin-bottom: .5em;
			&:last-child {
				margin: 0;
			}
		}
	}
}

// lightslider / コントロールボタンのJSオプションが効かないため非表示にする
.slider.kv {
	.lSSlideOuter .lSPager.lSpg {
		display: none;
	}
}
@include mq() {
	.tablepress {
		td {
			border: 0px solid $black_n !important;
		}
		.column-1 {
			display: block;
			width: 100%;
			background-color: $gray_r;
			font-size: .9rem;
		}
		.column-2 {
			display: block;
			width: 100%;
			font-size: .8rem;
		}
		a {
			display: inline;
			text-decoration: underline;
			color: $blue;
		}
		ul {
			li {
				margin-bottom: .5em;
				&:last-child {
					margin: 0;
				}
			}
		}
	}

}