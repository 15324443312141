/*
	=======================
		カードレイアウト / 4列
	=======================
*/
.cl_col4 {
	display: flex;
	flex-wrap: wrap;
	margin: 2em -.5em;
}
.card {
	width: 25%;
	margin-bottom: 1em;
	position: relative;
	transition: none;
	&:hover {
		.inner {
			&::after {
				width: calc(100% - 1em);
			}
		}
		.cnt {
			.title {
				color: $blue;
			}
		}
	}
	.inner {
		margin: 0 .5em;
		height: 100%;
		&::after {
			content: "";
			display: block;
			width: 2em;
			height: 1px;
			background-color: $blue;
			position: absolute;
			bottom: 0;
			left: .5em;
			transition: all .4s;
		}
	}
	.cnt {
		position: relative;
		padding: 1em 0;
		.title {
			font-size: 1.2rem;
			margin-bottom: .25em;
		}
		.excerpt {
			font-size: .9rem;
			margin: .5em auto;
		}
		.time {
			font-size: .8rem;
		}
	}
}
@include mq(tab) {
	.cl_col4 {
		margin: 0em -.5em;
	}
}
@include mq {
	.cl_col4 {
		margin: 0 auto;
	}
	.card {
		width: 100%;
		.inner {
			display: flex;
			justify-content: space-between;
			margin: 0;
			&::after {
				left: 0;
			}
		}
		.thumb {
			flex-basis: 35%;
		}
		.cnt {
			flex-basis: 62%;
			.title {
				font-size: 1rem;
			}
			.excerpt {
				font-size: .7rem;
				margin: .25em auto;
				line-height: 1.4;
			}
		}
	}
}