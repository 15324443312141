/*
	=======================
		ページ / admission
	=======================
*/
.admission {
	h2 {
		margin-bottom: 1em;
	}
	p {
		margin: 2em auto;
		line-height: 2;
	}
	ol {
		margin-left: 2em;
		li {
			margin: 1em auto;
			line-height: 1.8;
		}
	}
	& > #contact {
		padding-top: 2.5em;
		margin-top: -2.5em;
		& > h3 {
			margin-top: 0;
		}
	}
	.mw_wp_form {
		.form-wrap {
			border-bottom: 1px solid;
			margin-bottom: 2em;
			dl {
				display: table;
				border-top: 1px solid;
				border-left: 1px solid;
				border-right: 1px solid;
				width: 100%;
				&:last-child {
					border-bottom: 1px solid;
				}
				dt,dd {
					display: table-cell;
					padding: 1em;
					vertical-align: middle;
				}
				dt {
					width: 30%;
					border-right: 1px solid;
				}
				dd {
					width: 70%;
					input,select,textarea {
						width: 100%;
						appearance: none;
						padding: .5em 1em;
						background: #fff;
						font-size: 1rem;
						border-color: #ccc;
					}
					select {
						cursor: pointer;
						font-size: .9rem;
						line-height: 1.2;
					}
					textarea {
						padding: 1em;
					}
				}
			}
		}
		.agreement {
			appearance: checkbox !important;
		}
		.horizontal-item {
			display: inline-block;
			margin-right: 1em;
		}
		.mwform-radio-field {
			input {
				appearance: radio !important;
				width: auto !important;
				position: relative;
				top: -2px;
				margin-right: .5em !important;
			}
		}
		.mwform-tel-field {
			display: inline-flex;
			align-items: center;
			margin: 0 -1em;
			width: 100%;
			.tel {
				flex-basis: 30%;
				margin-left: 1em;
				margin-right: 1em;
			}
		}
		.required,.optional {
			font-size: .8rem;
			color: #fff;
			padding: .5em 1em .35em;
			line-height: 0;
			margin-right: 1em;
		}
		.required {
			background: $red;
		}
		.optional {
			background: $blue;
		}
		.agreement-wrap {
			p {
				margin: .5em auto;
				a {
					display: inline;
					text-decoration: underline;
					color: $blue;
				}
			}
		}
		.wp-form-inner {
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				right: .5rem;
				pointer-events: none;
				transform: translateY(-50%);
				width: 20px;
				height: 80%;
				background: url(../images/icon/ic_arrow_drop_down.png);
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}
}
.mwform-checkbox-field {
	input {
		appearance: checkbox !important;
		width: auto !important;
		position: relative;
		top: 2px;
	}
	label {
		display: flex;
	}
}
@include mq {
	.admission {
		.mw_wp_form {
			.form-wrap {
				dl {
					display: block;
					&:last-child {

					}
					dt,dd {
						display: block;
						width: 100%;
					}
					dt {
						border-right: none;
						border-bottom: 1px solid;
					}
					dd {

						input,select,textarea {

						}
						select {

						}
						textarea {

						}
					}
				}
			}
			.mwform-tel-field {
				margin: 0 -.5em;
				width: 106%;
				.tel {
					flex-basis: 30%;
					margin-left: .5em;
					margin-right: .5em;
					font-size: .9rem;
				}
			}
		}
	}
}