/*
	=======================
		ページ / opinion
	=======================
*/
.opini_img {
	justify-content: space-between;
	.item {
		width: 49%;
	}
}
@include mq {
	.opini_img {
		margin-bottom: 2em;
		display: block;
		.item {
			width: 100%;
		}
	}
}