//color
$orange: #ff5200;
$green: #00d4ab;
$blue: #0090D5;
$blue_r: #4492c0;
$blue_d: #4492c0;
$yellow: #ffc622;
$yellow_r: #FFFFC3;
$yellow_sr: #fdffd7;
$yellow_rank: #FFBB22;
$red: #ED343B;
$gray: #70706F;
$gray_r: #F4F6FA;
$gray_n: #E4EAF5;
$gray_rank: #CCD9DB;
$black_d: #1A1A1A;
$black_n: #191919;
$header-navitem-border: #BFBFBF;
$bronze: #E09742;
$facebook: #365899;
$twitter: #55acee;

//transtion
$normal: all .4s;

//gradation
$grad: linear-gradient(45deg, rgba(255,58,0,1) 0%, rgba(255,80,28,1) 25%, rgba(251,143,37,1) 100%);
$grad_o: linear-gradient(45deg, rgba(217,0,46,1) 0%, rgba(250,34,79,1) 25%, rgba(255,71,102,1) 40%, rgba(251,143,37,1) 100%);

//font
$mincho: "游明朝", YuMincho;